import { createBrowserRouter } from "react-router-dom";
import "./App.scss";
import "./components/style.scss";
import "./pages/style.scss";
import Home from "./pages/Home";
import GuanYuWoMen from "./pages/GuanYuWoMen";
import YeCaiYiTiHua from './pages/YeCaiYiTiHua';
import BanGongXieTong from './pages/BanGongXieTong';
import ZhiHuiWuGuan from "./pages/ZhiHuiWuGuan";
import GuanLiJiaShiCang from "./pages/GuanLiJiaShiCang";
import RenLiZiYuan from "./pages/RenLiZiYuan";
import PingPaiDongTai from "./pages/PingPaiDongTai";
import Cooperation from './pages/ShengTaiYuHeZuo';
import { pathMap } from "./common";
import WuLianWangJieJueFangAn from "./pages/WuLianWangJieJueFangAn";
import MoneyBag from "./pages/MoneyBag";

const router = createBrowserRouter([
	{
		path: `/${pathMap.home}`,
		element: <Home />
	},
	{
		path: `/${pathMap.guanYuWoMen}`,
		element: <GuanYuWoMen />
	},
	{
		path: `/${pathMap.yeCaiYiTiHuaJieJueFangAn}`,
		element: <YeCaiYiTiHua />
	},
	{
		path: `/${pathMap.zhiHuiWuGuanJieJueFangAn}`,
		element: <ZhiHuiWuGuan />
	},
	{
		path: `/${pathMap.renLiZiYuanPingTai}`,
		element: <RenLiZiYuan />
	},
	{
		path: `/${pathMap.banGongXieTongFangAn}`,
		element: <BanGongXieTong />
	},
	{
		path: `/${pathMap.guanLiJiaShiCang}`,
		element: <GuanLiJiaShiCang />
	},
	{
		path: `/${pathMap.wuLianWangJieJueFangAn}`,
		element: <WuLianWangJieJueFangAn />
	},
	{
		path: `/${pathMap.pingPaiDongTai}`,
		element: <PingPaiDongTai />
	},
	{
		path: `/${pathMap.pingPaiDongTai}/:id`,
		element: <PingPaiDongTai />
	},
	{
		path: `/${pathMap.shengTaiYuHeZuo}`,
		element: <Cooperation />
	},
	{
		path: `/${pathMap.moneyBag}`,
		element: <MoneyBag />
	},
	{
		path: "/",
		element: <Home />
	},
])

export default router;
