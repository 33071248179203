import Banner from "../../components/Banner"
import Block from "../../components/Block"
import BlockDescription from "../../components/BlockDescription"
import BlockTitle from "../../components/BlockTitle"
import FlexBox from "../../components/FlexBox"
import ImgBox from "../../components/ImgBox"
import Layout from "../../components/Layout"
import Page from "../../components/Page"
import { pathMap } from "../../common"
import { jump2page } from "../api"
import ItemList from "../../components/ItemList"
import { ReactNode } from "react"

export default function MoneyBag() {
    return <Page className="pingPaiDongTai">
        <Banner url="https://public.img.oss.shequbanjing.com/property/application/a5d72d61-ca95-4637-b8c4-bc62a53c4663.png">
            <Banner.Title>催费难用缴费无忧</Banner.Title>
            <Banner.Description>随着物业管理规模扩大和人力成本增加，物业费催缴成为难题，存在诸多问题，我们自研催缴方案，旨在为物业管理公司提供服务，降低催缴成本并提高成功率。</Banner.Description>
        </Banner>
        <Block className="block1">
            <BlockTitle style={{ textAlign: "center" }}>缴费无忧介绍</BlockTitle>
            <Layout type="LR" style={{ alignItems: "center" }}>
                <Layout.Left width={180} >
                    <ImgBox rate={1} url="https://public.img.oss.shequbanjing.com/property/application/e6641a4c-5cd2-4944-a20b-8d15efd13b5a.png" />
                </Layout.Left>
                <Layout.Right>
                    <div style={{ display: "flex", flexDirection: "column", rowGap: 30, color: "#1E2330", fontSize: 16, lineHeight: "28px", fontWeight: "bold" }}>
                        <div>
                            随着物业管理规模的不断扩大和人力成本的增加，物业费催缴成为物业管理公司面临的一大难题，沟通协调难度大，没有数据积累，很难形成固有的催费策略。
                        </div>
                        <div>
                            基于此，我们根据市场行业分析，自研了一套高效、智能、便捷的物业费催缴解决方案，旨在为物业管理公司提供全方位的物业费催缴服务，降低催缴成本，提高催缴成功率。
                        </div>
                    </div>
                </Layout.Right>
            </Layout>
        </Block>
        <Block style={{ background: "#EBF0F9", paddingTop: 57 }}>
            <BlockTitle style={{ textAlign: "center", fontSize: 30, lineHeight: "40px" }}>任务工作台，让催费有章法可循</BlockTitle>
            <Layout type="LR" style={{ marginTop: 50 }}>
                <Layout.Left width={525} >
                    <div style={{ marginTop: 32, display: "flex", flexDirection: "column", rowGap: 12 }}>
                        {[
                            {
                                title: "今日任务推送",
                                content: "自动抽离出今日需要联系的客户，不是想联系谁就联系谁。",
                            },
                            {
                                title: "呈现客户全貌",
                                content: "客户标签、欠费原因、上次催费结果清晰标注，最快速的了解客户。",
                            },
                            {
                                title: "多维度排序和检索",
                                content: "系统推荐多种排序规则、多维度搜索，辅助快速定位目标客户。",
                            },
                        ].map((o, idx) => <Cat key={idx} title={o.title}>{o.content}</Cat>)}
                    </div>
                </Layout.Left>
                <Layout.Right>
                    <ImgBox type="bg" height={525} style={{ backgroundPosition: "right top" }} url="https://public.img.oss.shequbanjing.com/property/application/b2d8f461-f1f9-4abd-a3dc-5ed02fbe4826.png" />
                </Layout.Right>
            </Layout>
        </Block>
        <Block style={{ background: "#EBF0F9", marginTop: -48 }}>
            <BlockTitle style={{ textAlign: "center", fontSize: 30, lineHeight: "40px" }}>批量催费：短信、微信及智能AI电话多渠道</BlockTitle>
            <Layout type="LR" style={{ marginTop: 56 }}>
                <Layout.Left style={{ flex: 1 }} >
                    <ImgBox type="bg" height={525} style={{ backgroundPosition: "left top" }} url="https://public.img.oss.shequbanjing.com/property/application/75cd98f8-f4d9-4ed5-a3a5-b6d26c0b8ec1.png" />
                </Layout.Left>
                <Layout.Right width={525}>
                    <div style={{ marginTop: 7, display: "flex", flexDirection: "column", rowGap: 12 }}>
                        {[
                            {
                                title: "短信发送",
                                content: "支持批量群发短信，快速通知欠费业主，避免了逐一编辑短信，信息可能出错的问题。",
                            },
                            {
                                title: "微信推送",
                                content: "通过微信把欠费明细发送给业主确认，方便沟通。",
                            },
                            {
                                title: "智能AI电话",
                                content: "采用人工智能技术，自动拨打业主电话，进行语音催费。",
                            },
                        ].map((o, idx) => <Cat key={idx} title={o.title}>{o.content}</Cat>)}
                    </div>
                </Layout.Right>
            </Layout>
        </Block>
        <Block style={{ background: "#EBF0F9", marginTop: -40, paddingBottom: 80 }}>
            <BlockTitle style={{ textAlign: "center", fontSize: 30, lineHeight: "40px" }}>自动催费：找到目标客户，精准定时发送</BlockTitle>
            <Layout type="LR" style={{ marginTop: 40 }}>
                <Layout.Left width={525} >
                    <div style={{ display: "flex", flexDirection: "column", rowGap: 12 }}>
                        {[
                            {
                                title: "目标客户匹配",
                                content: "根据业主的缴费习惯、欠费原因、特殊标签等信息，形成客户分类，匹配最佳催费话术。",
                            },
                            {
                                title: "灵活催费策略",
                                content: "针对不同目标客户、欠费周期，设置不同的频率的催费策略，提高催费成功率。",
                            },
                            {
                                title: "自动定时发送",
                                content: "可以设置发送时间，让催费信息在合适的时间段内发送给业主，提高阅读率。",
                            },
                        ].map((o, idx) => <Cat key={idx} title={o.title}>{o.content}</Cat>)}
                    </div>
                </Layout.Left>
                <Layout.Right>
                    <ImgBox rate={1} url="https://public.img.oss.shequbanjing.com/property/application/fbac60a0-64d5-48ce-bcde-388461f9c574.png" />
                </Layout.Right>
            </Layout>
        </Block>
        <Block style={{ paddingTop: 57, paddingBottom: 57 }}>
            <BlockTitle style={{ textAlign: "center", fontSize: 28 }}>海量市场催费话术库与个性化模板定制</BlockTitle>
            <div style={{ marginTop: 28, display: "flex", justifyContent: "center" }}>
                <ImgBox style={{ width: 913.51 }} rate={254 / 913.51} url="https://public.img.oss.shequbanjing.com/property/application/5fd6c84c-d99d-4f56-aeb0-0c3bea62e098.png" />
            </div>
            <div style={{ marginTop: 28, display: "flex", justifyContent: "center", columnGap: 34, textAlign: "center" }}>
                {[
                    {
                        title: "催费话术库",
                        content: "提供应用最多、效果最好的催费话术，针对不同场景和需求，快速选择。",
                    },
                    {
                        title: "个性化模板",
                        content: "根据不同客户群体和欠费原因，设计个性化的催费话术，提高沟通效果和客户满意度。",
                    },
                ].map((o, idx) => <div key={idx} style={{ display: "flex", alignItems: "center", justifyContent: "center", boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.10)", width: 440, height: 145, borderRadius: 10 }}>
                    <div style={{ width: 372, display: "flex", flexDirection: "column", justifyContent: "center", rowGap: 14 }}>
                        <div style={{ fontSize: 18, lineHeight: "24px", color: "#000000", fontWeight: "bold" }}>{o.title}</div>
                        <div style={{ fontSize: 14, lineHeight: "24px", color: "#686868" }}>{o.content}</div>
                    </div>
                </div>)}
            </div>
        </Block>
        <Block style={{ background: "#EBF0F9", paddingTop: 57, paddingBottom: 62 }}>
            <BlockTitle style={{ textAlign: "center", fontSize: 28 }}>数据看板，实时展示催费进度</BlockTitle>
            <div style={{ marginTop: 44, display: "flex", columnGap: 84, textAlign: "center" }}>
                {[
                    {
                        imgUrl: "https://public.img.oss.shequbanjing.com/property/application/97a37ea2-a16f-4c76-a33e-cc0f6f4e125d.png",
                        text1: "实时更新数据",
                        text2: "提供实时的物业费收缴数据和欠费明细，方便物业管理公司随时掌握财务状况。",
                    },
                    {
                        imgUrl: "https://public.img.oss.shequbanjing.com/property/application/e683cfc3-4cc3-4a9e-b050-685dc5f76262.png",
                        text1: "实时更新数据",
                        text2: "提供实时的物业费收缴数据和欠费明细，方便物业管理公司随时掌握财务状况。",
                    },
                    {
                        imgUrl: "https://public.img.oss.shequbanjing.com/property/application/ee3a9478-935c-4aab-877f-13094f161f53.png",
                        text1: "实时更新数据",
                        text2: "提供实时的物业费收缴数据和欠费明细，方便物业管理公司随时掌握财务状况。",
                    },
                ].map((o, idx) => <div key={idx} style={{ width: 242, boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.10)", borderRadius: "0px 6px 6px 0px", display: "flex", flexDirection: "column", rowGap: 0 }}>
                    <ImgBox height={297} url={o.imgUrl} />
                    <div style={{ background: "#115DF1", color: "#fff", fontSize: 18, lineHeight: "42px", fontWeight: "bold" }}>{o.text1}</div>
                    <div style={{ background: "#fff", height: 128, padding: 17, color: "#686868", fontSize: 14, lineHeight: "24px" }}>{o.text2}</div>
                </div>)}
            </div>
        </Block>
        <Block style={{ paddingTop: 57, paddingBottom: 57 }}>
            <BlockTitle style={{ textAlign: "center", lineHeight: "40px" }}>灵活选择套餐，控制成本</BlockTitle>
            <div style={{ marginTop: 33, color: "#686868", fontSize: 22, boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.10)", borderRadius: "8px 8px 6px 6px", overflow: "hidden" }}>
                <div style={{ background: "#FF714F", textAlign: "center", lineHeight: "55px", fontSize: 24, fontWeight: "bold", color: "#fff" }}>缴费无忧优惠方案</div>
                <div style={{ lineHeight: "50px" }}>
                    <Fish title1="套餐" title2="赠送" />
                    <Fish value1={98} value2={600} border />
                    <Fish value1={128} value2={1280} border />
                    <Fish value1={168} value2={1280} value3={120} />
                </div>
                <div style={{ padding: "18px 11px" }}>
                    <div style={{ background: "#E8F5FF", borderRadius: 3, fontSize: 16, lineHeight: "49px", color: "#365166", textAlign: "center" }}>
                        可根据实际情况，自由选择短信和电话量组合，更灵活地控制成本。
                    </div>
                </div>
            </div>
        </Block>
    </Page>
}

function Cat(props: { title: string; children: ReactNode }) {
    return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", rowGap: 3, background: "#fff", minHeight: 100, padding: "18px 18px 12px 26px", borderRadius: 8 }}>
        <div style={{ fontSize: 20, lineHeight: "28px", color: "#25429C", fontWeight: "bold" }}>{props.title}</div>
        <div style={{ fontSize: 16, lineHeight: "28px", color: "#1E2330" }}>{props.children}</div>
    </div>
}

function Fish(props: {
    border?: boolean;
    title1?: ReactNode;
    title2?: ReactNode;
    value1?: number;
    value2?: number;
    value3?: number;
}) {
    return <div style={{ display: "flex", justifyContent: "center", columnGap: 215, borderBottom: props.border ? `1px solid #E5E5E5` : undefined }}>
        <div style={{ width: 205, color: "#FF4F29", }}>
            {
                props.title1 &&
                <span style={{ fontWeight: "bold", color: "#111" }}>{props.title1}</span>
            }
            {
                props.value1 &&
                <><span style={{ fontWeight: "bold" }}>{props.value1}</span>元/月</>
            }
        </div>
        <div style={{ width: 307, color: "#686868" }}>
            {
                props.title2 &&
                <span style={{ fontWeight: "bold", color: "#111" }}>{props.title2}</span>
            }
            {
                props.value2 &&
                <><span style={{ fontWeight: "bold" }}>{props.value2}</span>条短信</>
            }
            {
                props.value3 &&
                <>+<span style={{ fontWeight: "bold" }}>{props.value3}</span>分钟通话</>
            }
        </div>
    </div>
}