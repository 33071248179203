import { CSSProperties, ReactNode } from "react"
import { Link, useNavigate } from "react-router-dom";
import { pathMap } from "../common";
import Footer from "./Footer";
import Header from "./Header";

type Props = {
    style?: CSSProperties;
    className: string;
    children: ReactNode | ReactNode[];
}

export default function Page(props: Props) {
    const navigate = useNavigate();
    return <div className={`component-page ${props.className}`} style={props.style} >
        <Header />
        {props.children}
        <Footer />
        <div className="destroyer">
            <div className="itemBox">
                <div className="item" onClick={() => navigate("/shengTaiYuHeZuo")} >
                    <div className="icon" style={{ backgroundImage: `url(${require("./imgs/destroyer/1.png")})` }} />
                    <Link to={`/${pathMap.shengTaiYuHeZuo}`}><div className="text">使用咨询</div></Link>
                </div>
            </div>
            <div className="itemBox">
                <div className="item">
                    <div className="icon" style={{ backgroundImage: `url(${require("./imgs/destroyer/2.png")})` }} />
                    <div className="text">电话联系</div>
                </div>
                <div className="frigate">
                    <div className="text1">客服热线</div>
                    <div className="text2">010-82887692</div>
                    <div className="text3">工作时间 9:30-18:30</div>
                </div>
            </div>
            <div className="itemBox">
                <div className="item" onClick={() => {
                    const a = document.createElement("a");
                    a.href = "https://portal.sqbj.com";
                    a.target = "_blank";
                    a.click();
                }}>
                    <div className="icon" style={{ backgroundImage: `url(${require("./imgs/destroyer/3.png")})` }} />
                    <div className="text" >产品体验</div>
                </div>
            </div>
        </div>
    </div>
}