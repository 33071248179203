export type Path =
    "home" |
    "guanYuWoMen" |
    "chanPinFangAn" | "yeCaiYiTiHuaJieJueFangAn" | "zhiHuiWuGuanJieJueFangAn" | "renLiZiYuanPingTai" | "banGongXieTongFangAn" | "guanLiJiaShiCang" | "wuLianWangJieJueFangAn" |
    "pingPaiDongTai" |
    "shengTaiYuHeZuo" |
    "moneyBag";

export const pathMap: { [key in Path]: key } = {
    "home": "home",
    "guanYuWoMen": "guanYuWoMen",
    "chanPinFangAn": "chanPinFangAn",
    "yeCaiYiTiHuaJieJueFangAn": "yeCaiYiTiHuaJieJueFangAn",
    "zhiHuiWuGuanJieJueFangAn": "zhiHuiWuGuanJieJueFangAn",
    "renLiZiYuanPingTai": "renLiZiYuanPingTai",
    "banGongXieTongFangAn": "banGongXieTongFangAn",
    "guanLiJiaShiCang": "guanLiJiaShiCang",
    "wuLianWangJieJueFangAn": "wuLianWangJieJueFangAn",
    "pingPaiDongTai": "pingPaiDongTai",
    "shengTaiYuHeZuo": "shengTaiYuHeZuo",
    "moneyBag": "moneyBag"
};