import { CSSProperties, ReactNode } from "react";

function Layout(props: { type: "LCR" | "LR"; style?: CSSProperties; children: ReactNode }) {
    return <div className={`component-layout ${props.type}`} style={props.style}>
        {props.children}
    </div>
}

function Left(props: { style?: CSSProperties; width?: number | string; children: ReactNode }) {
    return <div className="left" style={{ ...props.style, width: props.width }} >{props.children}</div>
}
function Center(props: { children: ReactNode }) {
    return <div className="center">{props.children}</div>
}
function Right(props: { width?: number; style?: CSSProperties; children: ReactNode }) {
    return <div className="right" style={{ ...props.style, width: props.width }} >{props.children}</div>
}

export default Object.assign(Layout, { Left, Center, Right });